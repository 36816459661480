import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Amplify from 'aws-amplify';
Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_xJkDV3YfR',
    userPoolWebClientId: '7f6m5ee8psjj0l465rj6asmghd'
  }
});


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
