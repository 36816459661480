import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';

export interface Review {
  id: string
  created: number
  reviewer: string
  lead: string
  customer: string
  order: string
  answers: any
  notes: string
}

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  public questions = [
    {
      id: 'q0',
      name: 'Placement and Alignment',
      description: 'Panels, frames and tiles/or desk units are aligned, leveled and in proper location per print.'
    },
    {
      id: 'q1',
      name: 'Power Jumpers, Receptacles, and Tables',
      description: 'All power jumpers, receptacles, and tables are installed per print and working.'
    },
    {
      id: 'q2',
      name: 'Wiring',
      description: 'All wire management is run properly per each application.'
    },
    {
      id: 'q3',
      name: 'Locks and Drawers',
      description: 'Keys, locks and drawers are functional.'
    },
    {
      id: 'q4',
      name: 'Punch Noted',
      description: 'All punch was noted on installer walk through.'
    },
    {
      id: 'q5',
      name: 'Punch Info',
      description: 'Punch info was provided and entered correctly and with enough detail.'
    },
    {
      id: 'q6',
      name: 'Cleaning',
      description: 'Furniture is cleaned (base, shelves, overheads, work surfaces, all glass, peds and files).'
    },
    {
      id: 'q7',
      name: 'Vacuuming and Debris',
      description: 'Floors are vacuumed, area is clean of debris (cardboard, Styrofoam).'
    }
  ]

  private rootUrl: string = '	https://bderemxpyc.execute-api.us-east-1.amazonaws.com'
  public customers: string[]
  public leads: string[]

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.getCustomers().then()
    this.getLeads().then()
  }

  async create(lead, customer, order, answers, notes) {
    let headers = await this.authService.getHeaders()
    let reviewer = await this.authService.getUsername()

    return this.http.post(
      `${this.rootUrl}/reviews`,
      { reviewer, lead, customer, order, answers, notes },
      { headers: headers }
    ).toPromise()
  }

  async deleteReview(id) {
    let headers = await this.authService.getHeaders()

    return this.http.delete(
      `${this.rootUrl}/reviews/${id}`,
      {headers: headers}
    ).toPromise()
  }

  async getReviews(): Promise<Review[]> {
    let headers = await this.authService.getHeaders()

    return this.http.get<Review[]>(
      `${this.rootUrl}/reviews`,
      {headers: headers}
    ).pipe(tap(res => {
      this.getCustomers().then()
      this.getLeads().then()
    })).toPromise()
  }

  async getCustomers(): Promise<string[]> {
    let headers = await this.authService.getHeaders()

    return this.http.get<string[]>(
      `${this.rootUrl}/customers`,
      {headers: headers}
    ).pipe(tap(res => {
      this.customers = res['customers'].sort((a, b) => {a < b ? 1 : -1})
    })).toPromise()
  }

  async getLeads(): Promise<string[]> {
    let headers = await this.authService.getHeaders()

    return this.http.get<string[]>(
      `${this.rootUrl}/leads`,
      {headers: headers}
    ).pipe(tap(res => {
      this.leads = res['leads'].sort((a, b) => {a < b ? 1 : -1})
    })).toPromise()
  }

}
