import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { Review, ReviewService } from 'src/app/services/review.service';
import { ReviewContentComponent } from '../review-content/review-content.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  public form: FormGroup
  public reviews: Review[]
  public leadReviews: Review[] = []
  public oldestTimestamp: number
  public lead: any

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private authService: AuthService,
    private reviewService: ReviewService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      lead: [''],
      dateRange: ['all']
    })

    this.reviewService.getReviews().then((res) => {
      this.reviews = res['reviews']
      this.form.get('lead').setValue(this.reviews[0].lead)
      this.setDateRange()
    })
  }

  updateViews() {
    let reviewCount = 0
    let averages = {}
    for (let q of this.reviewService.questions) {
      averages[q.id] = 0
    }

    this.leadReviews = []

    let name = this.form.get('lead').value
    for (let r of this.reviews.filter(x => x.lead === name && x.created > this.oldestTimestamp)) {
      this.leadReviews.push(r)
      reviewCount += 1
      for (let q of this.reviewService.questions) {
        averages[q.id] += r.answers[q.id]
      }
    }
    for (let a of Object.keys(averages)) {
      averages[a] = averages[a] / reviewCount
    }
    let summaryAverage = +Object.values(averages).reduce((t: number, v: number) => t + v, 0) / this.reviewService.questions.length

    this.lead = {
      name: name,
      averages: averages,
      summaryAverage: summaryAverage,
      reviewCount: reviewCount
    }
  }

  setLead() {
    this.updateViews()
  }

  setDateRange() {
    let d = new Date()
    d.setHours(0, 0, 0)
    d.setMilliseconds(0)
    
    if (this.form.get('dateRange').value === '1m') {
      this.oldestTimestamp = Math.round(new Date().setDate(d.getDate()-30) / 1000);
    } else if (this.form.get('dateRange').value === '3m') {
      this.oldestTimestamp = Math.round(new Date().setDate(d.getDate()-90) / 1000);
    } else if (this.form.get('dateRange').value === '6m') {
      this.oldestTimestamp = Math.round(new Date().setDate(d.getDate()-180) / 1000);
    } else if (this.form.get('dateRange').value === '12m') {
      this.oldestTimestamp = Math.round(new Date().setDate(d.getDate()-365) / 1000);
    } else if (this.form.get('dateRange').value === 'all') {
      this.oldestTimestamp = 0
    }

    this.updateViews()
  }

}
