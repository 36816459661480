import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup
  public waiting: boolean = false
  public failed: boolean = false

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  login() {
    this.failed = false
    this.waiting = true

    this.authService.login(
      this.loginForm.get('username').value,
      this.loginForm.get('password').value
    ).then(
      (res) => {
        if (res) {
          if (this.authService.isAdmin) {
            this.router.navigate(['dashboard'])
          } else {
            this.router.navigate(['new'])
          }
        } else {
          this.failed = true
          this.waiting = false
        }
      }
    )
  }

}
