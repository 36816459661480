import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControlName, FormControl } from '@angular/forms';
import { ReviewService } from 'src/app/services/review.service';
import { AuthService } from 'src/app/services/auth.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-new-review',
  templateUrl: './new-review.component.html',
  styleUrls: ['./new-review.component.scss']
})
export class NewReviewComponent implements OnInit {

  public form: FormGroup
  public waiting: boolean = false

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private reviewService: ReviewService,
    private authService: AuthService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      customer: ['', Validators.required],
      order: ['', Validators.required],
      lead: ['', Validators.required],
      notes: ['']
    })

    for (let q of this.reviewService.questions) {
      this.form.addControl(q.id, new FormControl('', [Validators.required]))
    }
  }

  createReview() {
    this.waiting = true
    let answers = {}
    for (let q of this.reviewService.questions) {
      answers[q.id] = this.form.get(q.id).value
    }
    this.reviewService.create(
      this.form.get('lead').value,
      this.form.get('customer').value,
      this.form.get('order').value,
      answers,
      this.form.get('notes').value
    ).then((res) => {
      this.form.markAsPristine()
      this.form.reset()
      if (this.authService.isAdmin) {
        this.router.navigate(['history'])
      } else {
        window.scroll(0, 0)
        alert('Review Submitted.')
        this.waiting = false
    }
    }).catch((err) => {
      alert(`Submission failed: ${JSON.stringify(err)}`)
      this.waiting = false
    })
  }

  setAnswer(question, value) {
    setTimeout(() => this.form.get(question).setValue(value))
    
  }

  canDeactivate() {
    if (!this.form.dirty) {
      return true
    }
    return this.dialogService.confirm('Discard changes?')
  }

}
