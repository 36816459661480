import { Component, OnInit } from '@angular/core';
import { Review, ReviewService } from 'src/app/services/review.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html'
})
export class HistoryComponent implements OnInit {

  public search: string = ''
  public reviews: Review[] = []

  public displayReviews: Review[] = []

  constructor(
    private reviewService: ReviewService
  ) { }

  ngOnInit() {
    this.reviewService.getReviews()
      .then((res) => {
        this.reviews = res['reviews'].sort((a, b) => a.created < b.created ? 1 : -1)
        this.displayReviews = this.reviews.filter(a => true)
      })
  }

  onSearch() {
    let s = this.search.toLowerCase()
    this.displayReviews = this.reviews.filter(
      (review: Review) => {
        if (review.lead.toLowerCase().includes(s)) {
          return true
        }
        if (review.customer.toLowerCase().includes(s)) {
          return true
        }
        if (review.order.toLowerCase().includes(s)) {
          return true
        }
      }
    )
  }

  onBackspace() {
    if (this.search === '') {
      this.onSearch()
    }
  }

}
