import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isAdmin: boolean = false
  public loggedIn: boolean = false

  constructor() {
    this.setLoggedInState()
    this.setAdminState()
  }

  private setLoggedInState(loggedIn?: boolean) {
    if (loggedIn !== undefined) {
      localStorage.setItem('loggedIn', loggedIn ? 'true': 'false')
    }
    this.loggedIn = localStorage.getItem('loggedIn') === 'true'
  }

  private setAdminState(isAdmin?: boolean) {
    if (isAdmin !== undefined) {
      localStorage.setItem('isAdmin', isAdmin ? 'true' : 'false')
    }
    this.isAdmin = localStorage.getItem('isAdmin') === 'true'
  }


  // Interface

  async login(username, password): Promise<any> {
    let res: boolean

    await Auth.signIn(username, password).then(
      (user) => {
        // console.log(`SignIn User: ${JSON.stringify(user)}`) // DEBUG
        this.setLoggedInState(true)
        let groups = user.signInUserSession.accessToken.payload["cognito:groups"]
        if (groups !== undefined) {
          this.setAdminState(groups.includes('Admin'))
        } else {
          this.setAdminState(false)
        }
        res = true
      },
      (err) => {
        console.log(`Login Failed: ${JSON.stringify(err)}`)
        res = false
      }
    )

    return res
  }

  logout(): void {
    Auth.signOut()
    this.setLoggedInState(false)
    this.setAdminState(false)
  }

  // TODO: Change to an HTTP interceptor.
  async getHeaders(): Promise<HttpHeaders> {
    let authorization = ''

    await Auth.currentSession().then(
      (user: any) => {
        authorization = `Bearer ${user.accessToken.jwtToken}`
        // console.log(authorization) // DEBUG
      }
    )

    return new HttpHeaders({
      'Authorization': authorization
    })
  }

  async getUsername(): Promise<string> {
    let username: string = ''
    
    await Auth.currentUserInfo().then(
      (user: any) => {
        // console.log(user) // DEBUG
        username = user.username
      }
    )

    return username
  }

}
