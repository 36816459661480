import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { NewReviewComponent } from './components/new-review/new-review.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HistoryComponent } from './components/history/history.component';
import { AuthGuard } from './guards/auth.guard';
import { ChangesGuard } from './guards/changes.guard';


const routes: Routes = [
  { path: 'login',      component: LoginComponent },
  { path: 'new',        component: NewReviewComponent, canActivate: [AuthGuard], canDeactivate: [ChangesGuard] },
  { path: 'dashboard',  component: DashboardComponent, canActivate: [AuthGuard], data: {admin: true} },
  { path: 'history',    component: HistoryComponent,   canActivate: [AuthGuard], data: {admin: true} },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
