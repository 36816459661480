import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'average'
})
export class AveragePipe implements PipeTransform {

  transform(dict: any): any {
    let sum = 0;
    for (let k of Object.keys(dict)) {
      sum += dict[k]
    }
    return sum / Object.keys(dict).length
  }

}
