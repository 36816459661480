import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Logged In check
    if (!this.authService.loggedIn) {
      this.router.navigate(['login'])
      return false
    }

    // Admin check
    let admin = next.data.admin as boolean
    if (admin) {
      if (!this.authService.isAdmin) {
        this.router.navigate['new'] // Just a non-admin page.
        return false
      }
    }

    return true
  }
  
}
