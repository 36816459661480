import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Review, ReviewService } from 'src/app/services/review.service';
import { DialogService } from 'src/app/services/dialog.service';

// @Component({
//   selector: 'app-review-content-delete-confirm',
//   template: `

//   `
// })

@Component({
  selector: 'app-review-content',
  templateUrl: './review-content.component.html'
})
export class ReviewContentComponent {

  @Input() review: Review

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private dialogService: DialogService,
    private reviewService: ReviewService,
  ) { }

  deleteReview() {
    this.dialogService.confirm('Delete Review?').subscribe((res: boolean) => {
      if (res) {
        this.activeModal.close({reason: 'delete', id: this.review.id})
      }
    })
  }

}
