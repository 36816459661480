import { Component, OnInit, Input } from '@angular/core';
import { Review, ReviewService } from 'src/app/services/review.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReviewContentComponent } from '../review-content/review-content.component';

@Component({
  selector: 'app-review-table',
  templateUrl: './review-table.component.html'
})
export class ReviewTableComponent implements OnInit {

  @Input() reviews: Review[]

  constructor(
    private modalService: NgbModal,
    private reviewService: ReviewService
  ) { }

  ngOnInit() {
  }

  openReview(review) {
    const modalRef = this.modalService.open(ReviewContentComponent)
    modalRef.componentInstance.review = review
    modalRef.result.then((modalResult) => {
      if (modalResult.reason === 'delete') {
        this.reviewService.deleteReview(modalResult.id)
        this.reviews = this.reviews.filter(x => x.id !== modalResult.id)
      }
    }).catch(() => null)
  }

}
